* {
  margin: 0px;
  padding: 0px;
}
.main-layout {
  height: calc(100vh - 76px); /*76 height of navigation   */
  overflow-y: scroll;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.technical-error-text {
  font-size: 20px;
  color: #583703;
  font-weight: 600;
  text-align: center;
  margin: 1rem 0rem;
}
.technical-error-image {
  width: 300px;
}
.bg_background_intro {
  width: 100%;
  height: 70vh;
  min-height: 200px;
  background-color: #fff7e8;
  border-radius: 10px;
}
.details_title {
  color: var(--unnamed-color-583703);
  font: normal normal 900 35px/57px Noto Sans;
  letter-spacing: 0px;
  color: #583703;
  text-transform: uppercase;
  padding-top: 1rem;
}
.bg_sub_title {
  color: var(--unnamed-color-583703);
  font: normal normal 500 20px/30px Noto Sans;
  letter-spacing: 0px;
  color: #583703;
}
.location_title {
  font: normal normal 500 16px/30px Noto Sans;
  letter-spacing: 0px;
  color: #583703;
}
.heading_div {
  color: var(--unnamed-color-583703);
  font: normal normal 900 32px/50px Noto Sans;
  letter-spacing: 0px;
  color: #583703;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.div_size {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.trust_img {
  border-radius: 10px;
  overflow: hidden;
}
.trust_img img {
  width: 100%;
  object-fit: cover;
  object-position: top center;
  height: 180px;
}
.temple_name {
  font: normal normal bold 20px/38px Noto Sans;
  color: #583703;
}
.temple_time {
  font: normal normal 400 16px/13px Noto Sans;
  color: #9c9c9c;
}
.temple_location {
  color: var(--unnamed-color-583703);
  font: normal normal 500 18px/30px Noto Sans;
  letter-spacing: 0px;
  color: #583703;
}
.docFileName {
  color: #583703;
}
.imagesScroller::-webkit-scrollbar {
  display: block;
  height: 5px;
}
.imagesScroller::-webkit-scrollbar-track {
  background: #ffd6bf;
  border-radius: 10px;
}
.imagesScroller::-webkit-scrollbar-thumb {
  background: #ff8744;
  border-radius: 10px;
}
@media screen and (max-width: 1199px) {
  .temple_name {
    font: normal normal bold 16px/38px Noto Sans;
  }
  .temple_time {
    font: normal normal 400 14px/13px Noto Sans;
    color: #9c9c9c;
  }
}
@media screen and (max-width: 767px) {
  .bg_sub_title {
    font: normal normal 500 16px/30px Noto Sans;
  }
  .temple_location {
    font: normal normal 500 15px/30px Noto Sans;
  }
  .location_title {
    font: normal normal 500 12px/30px Noto Sans;
  }
  .details_title {
    font: normal normal 900 26px/48px Noto Sans;
  }
  .heading_div {
    font: normal normal 900 22px/20px Noto Sans;
  }
  .temple_name {
    font: normal normal bold 16px/38px Noto Sans;
  }
  .temple_time {
    font: normal normal 400 14px/13px Noto Sans;
    color: #9c9c9c;
  }

  .bg_background_intro {
    height: 180px;
  }
}

@media screen and (max-width: 574px) {
  .technical-error-image {
    width: 100%;
  }
  .technical-error-text {
    font-size: 16px;
  }
  .bg_sub_title {
    font: normal normal 500 15px/30px Noto Sans;
  }
  .temple_location {
    font: normal normal 500 14px/30px Noto Sans;
  }
  .location_title {
    font: normal normal 500 11px/30px Noto Sans;
  }
  .details_title {
    font: normal normal 900 26px/48px Noto Sans;
  }
  .heading_div {
    font: normal normal 900 22px/20px Noto Sans;
  }
  .div_size {
    padding-top: 1rem;
  }
}
@media screen and (max-width: 494px) {
  .bg_sub_title {
    font: normal normal 500 14px/30px Noto Sans;
  }
  .temple_location {
    font: normal normal 500 13px/30px Noto Sans;
  }
  .location_title {
    font: normal normal 500 11px/30px Noto Sans;
  }
  .details_title {
    font: normal normal 900 20px/26px Noto Sans;
  }
  .heading_div {
    font: normal normal 900 18px/20px Noto Sans;
  }
}
@media screen and (max-width: 463px) {
  .bg_sub_title {
    font: normal normal 500 12px/16px Noto Sans;
  }
  .temple_location {
    font: normal normal 500 9px/30px Noto Sans;
  }
  .location_title {
    font: normal normal 500 8px/30px Noto Sans;
  }
  .details_title {
    font: normal normal 900 18px/26px Noto Sans;
  }
  .heading_div {
    font: normal normal 900 16px/20px Noto Sans;
    padding-bottom: 10px;
  }
}

/* Footer css */
.bg-footer {
  background-color: #ff8744;
  padding: 1rem 0px;
}
.copyright_text > div > span {
  cursor: pointer;
}
.logo {
  width: 166px;
  font: normal normal 900 30px/46px Noto Sans;
}
.copyright_text {
  color: white;
  font: normal normal 400 16px/27px Noto Sans;
}
a {
  cursor: pointer;
}
@media screen and (max-width: 1199px) {
  .logo {
    font: normal normal 900 26px/46px Noto Sans;
  }
  .copyright_text {
    font: normal normal 400 16px/27px Noto Sans;
  }
}
@media screen and (max-width: 991px) {
  .logo {
    font: normal normal 900 18px/25px Noto Sans;
  }
  .copyright_text {
    font: normal normal 400 14px/27px Noto Sans;
  }
}
@media screen and (max-width: 767px) {
  .copyright_text {
    font: normal normal 400 12px/27px Noto Sans;
  }
}
